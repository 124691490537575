import { getLuminance, hexToRgb } from '@mui/material/styles';

export function colorIsDark(colorHex: string) {
  if (!colorHex.startsWith('#')) {
    return false;
  }

  const luminance = getLuminance(hexToRgb(colorHex));
  return luminance < 0.179;
}

export function getContrastingColor(colorHex: string) {
  return colorIsDark(colorHex) ? '#ffffff' : '#000000';
}
