import { IInstanceResolver } from './lib/CustomXPathFunctions/Registry';
import { Instance } from './lib/Instance';
import InvalidXPathError from './lib/XPath/InvalidXPathError';
import { IXPathEvaluator, xPathEvaluator } from './lib/XPath/XPathEvaluator';
import XPathReferenceParser from './lib/XPath/XPathParser';
import { validateXPath } from './lib/XPath/XPathValidator';

const throwInstanceResolver = () => {
  throw new Error('InstanceResolver not registered');
};

const createXPathEvaluator = (instanceResolver?: IInstanceResolver): IXPathEvaluator => {
  xPathEvaluator.instanceResolver = instanceResolver ?? throwInstanceResolver;
  return xPathEvaluator;
};

export * from './lib/BindHelpers';
export * from './lib/ItemsetHelpers';

export { createXPathEvaluator, Instance, InvalidXPathError, validateXPath, XPathReferenceParser };
export type { IInstanceResolver, IXPathEvaluator };
