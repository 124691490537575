export const arTranslations = {
  //Modern standard Arabic
  ar: {
    metadata: {
      debugDisplayName: 'Arabic',
    },
    translations: {
      delete: 'حذف',
      cancel: 'إلغاء',
      add: 'إضافة',
      clear: 'إزالة',
      rotate: 'تدوير',
      noOptionsFound: 'لم يتم العثور على خيارات.',
      wizard: {
        nextStep: 'الخطوة التالية',
        previousStep: 'الخطوة السابقة',
        submit: 'إرسال',
      },
      validationPopup: {
        heading: 'أخطاء التحقق من صحة البيانات',
        message:
          'يحتوي النموذج على أخطاء عند التحقق من صحة البيانات. يرجى تصحيح أية حقول غير صحيحة.',
      },
      submissionFailedPopup: {
        heading: 'فشل الإرسال',
        message: 'حدث خطأ أثناء إرسال النموذج. تحقق من الاتصال وحاول مرة أخرى.',
      },
      requestInFlightPopup: {
        heading: 'طلب مُعَلَّق لتنفيذ طلب سابق',
        message:
          'غير قادر على المتابعة حتى تمام تنفيذ الطلب الحالي. يرجى المحاولة مرة أخرى في وقت لاحق.',
      },
      booleanButtons: {
        yes: 'نعم',
        no: 'لا',
      },
      otherControl: {
        other: 'أخرى',
      },
      splitDate: {
        day: 'يوم',
        month: 'شهر',
        year: 'سنة',
      },
      partialDate: {
        full: 'التاريخ بالكامل',
        monthYear: 'شهر وسنة',
        year: 'سنة فقط',
      },
      formWarning: {
        FORM_WITHDRAWN: {
          banner: 'تم سحب هذا النموذج من الاستخدام. قد تكون المعلومات التي يحتوي عليها غير دقيقة.',
          dialog: {
            title: 'تم سحب النموذج',
            content:
              'تم سحب هذا النموذج من الاستخدام. قد تكون المعلومات التي يحتوي عليها غير دقيقة.',
          },
        },
      },
      annotatedImage: {
        noAnnotations: 'لا يوجد أي تعليقات إيضاحية',
      },
      repeat: {
        deleteRow: 'حذف الآن',
        deleteRowConfirmation: {
          title: 'تأكيد الحذف',
          message: 'هل أنت متأكد أنك تريد حذف الصف؟ لا يمكن التراجع عن هذا الإجراء.',
        },
      },
      freetextTranslations: {
        autotranslateLabel: '{{dateTime}} نص مُترجم آليًا بتاريخ',
        showOriginalText: 'إظهار النص الأصلي',
        hideOriginalText: 'إخفاء النص الأصلي',
      },
      footer: {
        externalLinks: {
          accessibility: 'إمكانية الدخول',
          privacy: 'سياسة الخصوصية',
          autoTranslate: 'سياسة الترجمة التلقائية',
        },
      },
    },
  },
};
