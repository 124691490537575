import { validAttributeName } from '../BindHelpers';
import { hashLiteral, keyedHashLiteral } from './Functions/HashingLiteral';
import { ICurrentNodeResolver, IInstanceResolver, IXPathRegister } from './Registry';

const registerChooseFunction = (register: IXPathRegister) =>
  register(
    'fn:choose',
    ['xs:boolean', 'item()*', 'item()*'],
    'item()*',
    (_context: any, conditionResult: boolean, trueValue: any, falseValue: any) =>
      conditionResult ? trueValue : falseValue,
  );

const registerInstanceFunction = (register: IXPathRegister, resolver: () => IInstanceResolver) =>
  register(
    'fn:instance',
    ['xs:string'],
    'item()',
    (_context: any, instanceId: string) => resolver()(instanceId).xml.documentElement,
  );

const registerCurrentFunction = (
  register: IXPathRegister,
  currentNodeResolver: ICurrentNodeResolver,
) => register('fn:current', [], 'item()', (_context: any) => currentNodeResolver());

const registerValidFunction = (register: IXPathRegister) =>
  register('fn:valid', ['node()*'], 'xs:boolean', (_context: any, nodes: Element[]) => {
    if (nodes.length === 0) {
      return true;
    }

    return nodes.every((node) => node.getAttribute(validAttributeName) === 'true');
  });

const registerDaysFromDateFunction = (register: IXPathRegister) =>
  register('fn:days-from-date', ['xs:date'], 'xs:integer', (_context: any, date: Date) => {
    return Math.floor(date.getTime() / 8.64e7);
  });

const registerSecondsFromDateTimeFunction = (register: IXPathRegister) =>
  register(
    'xf:seconds-from-dateTime',
    ['xs:dateTime'],
    'xs:integer',
    (_context: any, date: Date) => {
      return Math.floor(date.getTime() / 1000);
    },
  );

const registerDigestFunctions = (register: IXPathRegister) => {
  register(
    'xf:digest',
    ['xs:string', 'xs:string', 'xs:string?'],
    'xs:string',
    (_context: any, data: string, algo: string, encoding?: string) => {
      return hashLiteral(data, algo, encoding);
    },
  );

  register(
    'xf:digest',
    ['xs:string', 'xs:string'],
    'xs:string',
    (_context: any, data: string, algo: string) => {
      return hashLiteral(data, algo);
    },
  );
};

const registerHmacFunctions = (register: IXPathRegister) => {
  register(
    'xf:hmac',
    ['xs:string', 'xs:string', 'xs:string', 'xs:string?'],
    'xs:string',
    (_context: any, secret: string, data: string, algo: string, encoding?: string) => {
      return keyedHashLiteral(secret, data, algo, encoding);
    },
  );

  register(
    'xf:hmac',
    ['xs:string', 'xs:string', 'xs:string'],
    'xs:string',
    (_context: any, secret: string, data: string, algo: string) => {
      return keyedHashLiteral(secret, data, algo);
    },
  );
};

export const registerXFormsFunctions = (
  xpathRegister: IXPathRegister,
  instanceResolver: () => IInstanceResolver,
  currentNodeResolver: ICurrentNodeResolver,
) => {
  registerInstanceFunction(xpathRegister, instanceResolver);
  registerChooseFunction(xpathRegister);
  registerCurrentFunction(xpathRegister, currentNodeResolver);
  registerValidFunction(xpathRegister);
  registerDaysFromDateFunction(xpathRegister);
  registerSecondsFromDateTimeFunction(xpathRegister);
  registerDigestFunctions(xpathRegister);
  registerHmacFunctions(xpathRegister);
};
