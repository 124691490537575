import { ITypeDefinition } from './TypeDefinitionFactory';

export default class IntegerDefinition implements ITypeDefinition {
  //private static regex: RegExp = new RegExp("^(\-|\+)?([0-9]+)$");

  private readonly minInclusive = -2147483648;
  private readonly maxInclusive = 2147483648;

  formatToLocale(value: string) {
    return value;
  }

  parseToXmlValue(value: string) {
    return value;
  }

  isValid(value: string) {
    const asNumber = Number(value);
    const hasDecimalPoint = value.includes('.');
    const isInteger = Number.isInteger(asNumber);

    return (
      isInteger &&
      !hasDecimalPoint &&
      asNumber <= this.maxInclusive &&
      asNumber >= this.minInclusive
    );
  }
}
