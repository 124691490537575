export const idTranslations = {
  id: {
    metadata: {
      debugDisplayName: 'Indonesian',
    },
    translations: {
      delete: 'Hapus',
      cancel: 'Batalkan',
      add: 'Tambah',
      clear: 'Kosongkan',
      rotate: 'Putar',
      noOptionsFound: 'Tidak ada opsi yang ditemukan.',
      wizard: {
        nextStep: 'Langkah selanjutnya',
        previousStep: 'Langkah sebelumnya',
        submit: 'Kirim',
      },
      validationPopup: {
        heading: 'Kesalahan validasi',
        message: 'Formulir berisi kesalahan validasi. Harap perbaiki kolom yang tidak valid.',
      },
      submissionFailedPopup: {
        heading: 'Pengajuan gagal',
        message: 'Terjadi kesalahan saat mengirimkan formulir. Periksa koneksi Anda dan coba lagi.',
      },
      requestInFlightPopup: {
        heading: 'Permintaan dalam penerbangan yang luar biasa',
        message:
          'Tidak dapat melanjutkan hingga permintaan saat ini selesai. Silakan coba lagi sebentar lagi.',
      },
      booleanButtons: {
        yes: 'Ya',
        no: 'Tidak',
      },
      otherControl: {
        other: 'Lainnya',
      },
      splitDate: {
        day: 'Hari',
        month: 'Bulan',
        year: 'Tahun',
      },
      partialDate: {
        full: 'Tanggal lengkap',
        monthYear: 'Bulan dan tahun',
        year: 'Hanya tahun',
      },
      formWarning: {
        FORM_WITHDRAWN: {
          banner:
            'Formulir ini telah dicabut dari penggunaan. Informasi yang dikandungnya mungkin tidak akurat.',
          dialog: {
            title: 'Formulir dicabut',
            content:
              'Formulir ini telah dicabut dari penggunaan. Informasi yang dikandungnya mungkin tidak akurat.',
          },
        },
      },
      annotatedImage: {
        noAnnotations: 'Tidak ada anotasi yang dicatat',
      },
      repeat: {
        deleteRow: 'Hapus baris',
        deleteRowConfirmation: {
          title: 'Konfirmasi penghapusan',
          message:
            'Apakah Anda yakin ingin menghapus baris tersebut? Tindakan ini tidak bisa dibatalkan.',
        },
      },
      freetextTranslations: {
        autotranslateLabel: 'Teks yang diterjemahkan otomatis pada {{dateTime}}',
        showOriginalText: 'Tampilkan teks asli',
        hideOriginalText: 'Sembunyikan teks asli',
      },
      footer: {
        externalLinks: {
          accessibility: 'Aksesibilitas',
          privacy: 'Kebijakan Privasi',
          autoTranslate: 'Kebijakan Terjemahan Otomatis',
        },
      },
    },
  },
};
