import axios from 'axios';
import { ErrorInfo } from 'react';

const logReactError = (error: Error, info: ErrorInfo) => {
  if (error.name === 'ChunkLoadError') {
    return;
  }

  const message = [
    'URI: ' + window.location.href,
    'Message: ' + error.message,
    'Stack: ' + error.stack,
  ].join(' - ');

  axios('/logs/reacterror', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      error: message,
      componentStack: info.componentStack,
    }),
  });
};

const logError = (error: Error) => {
  const message = [
    'URL: ' + window.location.href,
    'Message: ' + error.message,
    'Stack: ' + error.stack,
  ].join(' - ');

  axios('/logs/clienterror', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({ message }),
  });
};

const errorMessageIgnoreList = [
  'resizeobserver loop limit exceeded',
  'resizeobserver loop completed with undelivered notifications',
];

const errorSourceIgnoreList = [
  'chrome-extension',
  'safari-extension',
  'moz-extension',
  'https://www.gstatic.com/recaptcha/',
];

const logConsoleError = (
  event: Event | string,
  source?: string,
  fileno?: number,
  columnNumber?: number,
  error?: Error,
) => {
  if (typeof event === 'string') {
    const string = event.toLowerCase();
    const substring = 'script error';
    if (string.includes(substring)) {
      alert('Script Error: See Browser Console for Detail');
      return;
    }

    for (const ignore of errorMessageIgnoreList) {
      if (string.includes(ignore)) {
        return;
      }
    }

    if (source) {
      for (const ignore of errorSourceIgnoreList) {
        if (source.includes(ignore)) {
          return;
        }
      }
    }

    const message = [
      'Message: ' + event,
      'URL: ' + window.location.href,
      'Source: ' + source,
      'Line: ' + fileno,
      'Column: ' + columnNumber,
      'Error object: ' + JSON.stringify(error),
    ].join(' - ');

    axios('/logs/clienterror', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      data: JSON.stringify({ message }),
    });
  }
};

export { logConsoleError, logError, logReactError };
